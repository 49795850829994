import axios from "axios"
import applyCaseMiddleware from "axios-case-converter"
import { getRegionSetsOfDrivers, getRegionSetsOfOrg } from "../../lib"
import { fetchAllPages, PaginationResponse } from "../utils"
import {
  AlimtalkCondition,
  AlimtalkEventType,
  Cart,
  ClientsSummary,
  CreateRouteRequest,
  CreateStationRequest,
  Driver,
  FileCategory,
  FileResponse,
  HoldOrQuitType,
  IEventWindow,
  IResourceStats,
  Issue,
  IssueComment,
  IStats,
  ListCartsInStationResponse,
  ListDriverRoundsResponse,
  ListDriversResponse,
  ListFailedAlimtalksResponse,
  ListIssuesResponse,
  ListLoadPossessorsResponse,
  ListLoadsResponse,
  Load,
  LocationContext,
  Organization,
  OrgId,
  OrgIds,
  PolicyTypes,
  RegionSet,
  RetryFailedAlimtalkReponse,
  RetryFailedAlimtalkRequest,
  Round,
  Route,
  SendManualAlimtalkRequest,
  SendManualAlimtalkResponse,
  Station,
  Truck,
} from "./types"
import { BuildingNote } from "./types/BuildingNote"
import { RouteFlow } from "./types"
import { TodayAPIClient } from "../common"

// TODO: 추후 100으로 복원해야 함
export const LIST_PAGE_SIZE = "500"
const HANGANG_ORG_IDS: OrgId[] = [OrgIds.hangang, OrgIds.hangangPickUp]

export type LoadQuitReason = "DAMAGED" | "LOST" | "CLIENT_REQUEST" | "TEST"
export type LoadReturnReason = "DAMAGED" | "LOST" | "CLIENT_REQUEST" | "TEST"

export class Tracker extends TodayAPIClient {
  constructor(baseUrl: string, token?: string) {
    super(
      applyCaseMiddleware(
        axios.create({
          baseURL: baseUrl,
          headers: {
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
          },
        })
      )
    )
  }

  // Track APIs

  async getRound(roundId: string) {
    const { data } = await this.client.get(`/track/v1/rounds/${roundId}`)
    return data as Round
  }

  async createRoundEvent(
    roundId: string,
    stationId: string,
    eventType:
      | "ARRIVAL_AT_STATION"
      | "DEPARTURE_FROM_STATION"
      | "RETURNING_TO_STATION"
  ): Promise<void> {
    await this.client.post(`/track/v1/rounds/${roundId}/events`, {
      eventType,
      stationId,
    })
  }

  // Driving APIs

  async createStation(request: CreateStationRequest) {
    const { data } = await this.client.post("/driving/v1/stations", request)
    return data as Station
  }

  async createRoute(request: CreateRouteRequest) {
    const { data } = await this.client.post("/driving/v1/routes", request)
    return data as Route
  }

  async listRoutes(
    queryParams: Record<string, string>
  ): Promise<PaginationResponse<"routes", Route>> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(`/driving/v1/routes?${paramsString}`)
    return data
  }

  async listRoutesWithFetchingAllPages(
    queryParams: Record<string, string>
  ): Promise<Route[]> {
    if (!queryParams.hasOwnProperty("page_size")) {
      queryParams["page_size"] = "500"
    }
    return await fetchAllPages<"routes", Route>(
      "routes",
      { ...queryParams },
      async (q) => {
        const paramsString = new URLSearchParams(q)
        const { data } = await this.client.get(
          `/driving/v1/routes?${paramsString}`
        )
        return data
      }
    )
  }

  async listOrgRoutes(
    orgId: OrgId,
    queryParams: Record<string, string>
  ): Promise<Route[]> {
    return await this.listRoutesWithFetchingAllPages(queryParams)
  }

  async retrieveRoute(routeId: string): Promise<Route> {
    const { data } = await this.client.get(`/driving/v1/routes/${routeId}`)
    return data as Route
  }

  async listStations(
    pageToken = "",
    pageSize = LIST_PAGE_SIZE
  ): Promise<PaginationResponse<"stations", Station>> {
    const { data } = await this.client.get(
      `/driving/v1/stations?page_size=${pageSize}&page_token=${pageToken}`
    )
    return data as PaginationResponse<"stations", Station>
  }

  async retrieveStation(stationId: string): Promise<Station> {
    const { data } = await this.client.get(`/driving/v1/stations/${stationId}`)
    return data as Station
  }

  async finishRound(driverId: string, roundId: string): Promise<Round> {
    const { data } = await this.client.post(
      `/driving/v1/drivers/${driverId}/rounds/${roundId}/finish`,
      {
        location: {},
      }
    )
    return data as Round
  }

  // Shipping APIs

  async listCarts(
    pageToken = "",
    pageSize = LIST_PAGE_SIZE,
    filter: string
  ): Promise<PaginationResponse<"carts", Cart>> {
    const params = new URLSearchParams({
      page_token: pageToken,
      page_size: pageSize,
    })
    if (filter) {
      params.append("filter", filter)
    }
    const { data } = await this.client.get(`/shipping/v1/carts?${params}`)
    return data
  }

  async retrieveCart(cartId: string): Promise<Cart> {
    const { data } = await this.client.get(`/shipping/v1/carts/${cartId}`)
    return data as Cart
  }

  async openCart(stationId: string, cartId: string) {
    return await this.client.post(
      `/shipping/v1/stations/${stationId}/carts/${cartId}/open`
    )
  }

  async closeCart(stationId: string, cartId: string) {
    return await this.client.post(
      `/shipping/v1/stations/${stationId}/carts/${cartId}/close`
    )
  }

  async moveCart(stationId: string, targetCartId: string) {
    return await this.client.post(`/shipping/v1/carts/${targetCartId}/move`, {
      stationId,
    })
  }

  async listCartLoads(cartId: string): Promise<Load[]> {
    const { data } = await this.client.get(`/shipping/v1/carts/${cartId}/loads`)
    return data.loads as Load[]
  }

  async listLoadsInTruck(truckId: string, queryParams: Record<string, string>) {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/shipping/v1/trucks/${truckId}/loads?${paramsString}`
    )
    return data as PaginationResponse<"loads", Load>
  }

  async retrieveLoad(loadId: string): Promise<Load> {
    const { data } = await this.client.get(`/shipping/v1/loads/${loadId}`)
    return data as Load
  }

  async listLoadAlimtalkConditions(
    loadId: string
  ): Promise<Map<AlimtalkEventType, AlimtalkCondition>> {
    const { data } = await this.client.get(
      `/shipping/v1/loads/${loadId}/alimtalk-conditions`
    )
    return data.alimtalkConditions
  }

  async listOrgRegionSets(orgId: OrgId): Promise<RegionSet[]> {
    const regionSets = await fetchAllPages<"regionSets", RegionSet>(
      "regionSets",
      {
        policyType: `TODAY_EVENING`,
      },
      (q) =>
        this.listRegionSets(
          q as { [key: string]: string },
          q["page_token"],
          q["page_size"]
        )
    )
    const routes = await this.listRoutesWithFetchingAllPages({
      filter: `type=LM_GENERAL;policy_type=TODAY_EVENING`,
    })

    // 강동물류는 네이밍 컨벤션 기반으로 필터링한다.
    if (orgId === OrgIds.kdlogistics) {
      return getRegionSetsOfOrg(orgId, regionSets)
    }

    // 그 외 외주 협력사 (e.g. 한강, 선영, 서원, 고박스 등)는 Driver 기반으로 필터링한다.
    const drivers: Driver[] = await this.listOrgDrivers(orgId)
    return getRegionSetsOfDrivers(drivers, regionSets, routes)
  }

  async getRestrictedLastMileRegionSetFilter(
    orgId: OrgId,
    queryParams: Record<string, string>
  ): Promise<Record<string, string>> {
    const { filter } = queryParams
    const filters = filter.split(";")
    const regionSetFilters = filters.filter(
      (f) => f && f.startsWith("last_mile_region_set=")
    )

    let regionSetsOfOrg: RegionSet[] = await this.listOrgRegionSets(orgId)

    if (regionSetFilters.length === 0) {
      // last_mile_region_set 필터가 없는 경우, 외주 협력사가 담당하는 RegionSet 목록을 기본 필터로 한다.
      filters.push(
        `last_mile_region_set=${regionSetsOfOrg
          .map((rs) => `${rs.regionSetPolicyType}:${rs.regionSetName}`)
          .join(",")}`
      )
      queryParams.filter = filters!.join(";")
    } else if (regionSetFilters.length === 1) {
      // last_mile_region_set 필터가 있는 경우, 외주 협력사가 담당하지 않는 RegionSet 목록이 포함되어 있는지 확인한다.
      for (const filteredRegionSet of regionSetFilters[0]
        .split("=")[1]
        .split(",")) {
        if (
          !regionSetsOfOrg
            .map((rs) => `${rs.regionSetPolicyType}:${rs.regionSetName}`)
            .includes(filteredRegionSet)
        ) {
          throw new Error("selected last_mile_region_set filter not permitted.")
        }
      }
    } else {
      throw new Error("last_mile_region_set filter must be unique.")
    }

    return queryParams
  }

  async listLoadsWithFetchingAllPages(
    queryParams: Record<string, string>,
    updateProgress?: (
      current?: number | ((prev: number) => number),
      total?: number | ((prev: number) => number)
    ) => void
  ): Promise<Load[]> {
    return await fetchAllPages<"loads", Load>(
      "loads",
      { ...queryParams },
      async (q) => {
        const paramsString = new URLSearchParams(q)
        const { data } = await this.client.get(
          `/shipping/v1/loads?${paramsString}`
        )
        return data
      },
      undefined,
      undefined,
      updateProgress
    )
  }

  async listLoads(queryParams: Record<string, string>): Promise<Load[]> {
    const { orgId } = queryParams

    if (orgId) {
      queryParams = await this.getRestrictedLastMileRegionSetFilter(
        orgId as OrgId,
        queryParams
      )
    }

    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(`/shipping/v1/loads?${paramsString}`)
    return data as Load[]
  }

  async getLoad(loadId: string) {
    const { data } = await this.client.get(`/shipping/v1/loads/${loadId}`)
    return data as Load
  }

  async moveLoad(
    stationId: string,
    targetCartId: string,
    loadId: string,
    moveType: "CLASSIFY" | "GROUP_CLASSIFY" | "UNDO_CLASSIFY" | "MOVE"
  ) {
    return await this.client.post(
      `/shipping/v1/stations/${stationId}/loads/${loadId}/move`,
      {
        moveType,
        targetCartId,
      }
    )
  }

  async listStationCarts(
    stationId: string
  ): Promise<ListCartsInStationResponse> {
    const { data } = await this.client.get(
      `/shipping/v1/stations/${stationId}/carts`
    )
    return data as ListCartsInStationResponse
  }

  async createStationEvents(stationId: string, events: any[]) {
    return this.client.post(
      `/shipping/v1/stations/${stationId}/events/bulk-create`,
      { events }
    )
  }

  async handoverLoad(loadId: string, receiverDriverId: string) {
    await this.client.post(`/shipping/v1/loads/${loadId}/handover`, {
      receiverDriverId,
    })
  }

  async holdLoad(
    loadId: string,
    type: string,
    reason: string,
    specialNote?: string,
    imageUrl: string = "-",
    extraPayload: any = {}
  ): Promise<Load> {
    const { data: load } = await this.client.post(
      `/shipping/v1/loads/${loadId}/hold`,
      {
        type,
        reason,
        specialNote,
        // Placeholder data
        imageUrl: imageUrl,
        location: {
          latitude: 0,
          longitude: 0,
          accuracyMeters: 0,
          measureTime: new Date().toISOString(),
        },
        extraPayload,
      }
    )
    return load as Load
  }

  async pickUpLoad({
    loadId,
    truckId,
    imageUrl,
  }: {
    loadId: string
    truckId: string
    imageUrl: string
  }) {
    const { data: load } = await this.client.post(
      `/shipping/v1/loads/${loadId}/pickup`,
      {
        truckId,
        imageUrl,
        handOverMethod: "포탈에서 강제 인수 처리",
        location: { latitude: 0.1, longitude: 0.1 } as LocationContext,
      }
    )
    return load as Load
  }

  async readyLoad(
    loadId: string,
    readyTime: string,
    accessMethod?: string,
    preference?: string
  ): Promise<Load> {
    const { data } = await this.client.post(
      `/shipping/v1/loads/${loadId}/ready`,
      { readyTime, accessMethod, preference }
    )
    return data as Load
  }

  async bulkUpdatePlannedTruckId(
    loadIds: string[],
    plannedTruckId: string
  ): Promise<{ loads: Load[] }> {
    const { data } = await this.client.post(
      `/shipping/v1/loads/bulk-update-planned-truck-id`,
      { loadIds, plannedTruckId }
    )
    return data as { loads: Load[] }
  }

  async bulkResendLoadEvents(
    eventType: "load_deliver",
    loadIds: string[]
  ): Promise<void> {
    await this.client.post(`/util/v1/events/bulk-resend-load-events`, {
      eventType,
      loadIds,
    })
  }

  async updateReceiverInfo(
    loadId: string,
    accessMethod?: string,
    preference?: string
  ): Promise<Load> {
    const { data } = await this.client.patch(
      `/shipping/v1/loads/${loadId}/receiver`,
      { accessMethod, preference }
    )
    return data as Load
  }

  async unholdLoad(
    loadId: string,
    accessMethod?: string,
    preference?: string
  ): Promise<Load> {
    const { data } = await this.client.post(
      `/shipping/v1/loads/${loadId}/unhold`,
      { accessMethod, preference }
    )
    return data as Load
  }

  async searchLoads(query: {
    type:
      | "id"
      | "active_exact_id"
      | "name"
      | "invoice_number"
      | "client_shipping_id"
      | "forwarding_invoice_number"
      | "sender.name"
      | "receiver.name"
      | "sender.phone"
      | "receiver.phone"
      | "uncollected_by_truck"
      | "uncollected_in_region_set"
      | "delivered_in_round"
      | "held_delivery_in_route"
      | "resumable_delivery_in_route"
    input: string
    pageSize?: number
    pageToken?: string
  }): Promise<PaginationResponse<"loads", Load>> {
    const { type, input, pageSize, pageToken } = query
    const params = new URLSearchParams({
      ...(pageToken ? { page_token: pageToken } : {}),
      ...(pageSize ? { page_size: pageSize.toString() } : {}),
    })
    const { data } = await this.client.get(
      `/shipping/v1/loads/search?query=${type}=${input}&${params}`
    )
    return data as PaginationResponse<"loads", Load>
  }

  async searchLoadById(
    invoiceNumber: string,
    exact?: boolean
  ): Promise<Load | null> {
    if (!invoiceNumber) {
      return null
    }
    const { loads } = await this.searchLoads({
      type: "id",
      input: (exact ? "" : "*") + invoiceNumber + (exact ? "" : "*"),
    })
    return loads?.[0] ?? null
  }

  async searchLoadByInvoiceNumber(
    invoiceNumber: string,
    exact?: boolean
  ): Promise<Load | null> {
    if (!invoiceNumber) {
      return null
    }
    const { loads } = await this.searchLoads({
      type: "invoice_number",
      input: (exact ? "" : "*") + invoiceNumber + (exact ? "" : "*"),
    })
    return loads?.[0] ?? null
  }

  async searchLoadByForwardingInvoiceNumber(
    invoiceNumber: string,
    exact?: boolean
  ): Promise<Load | null> {
    if (!invoiceNumber) {
      return null
    }
    const { loads } = await this.searchLoads({
      type: "forwarding_invoice_number",
      input: (exact ? "" : "*") + invoiceNumber + (exact ? "" : "*"),
    })
    return loads?.[0] ?? null
  }

  async searchLoadByClientShippingId(
    invoiceNumber: string,
    exact?: boolean
  ): Promise<Load | null> {
    if (!invoiceNumber) {
      return null
    }
    const { loads } = await this.searchLoads({
      type: "client_shipping_id",
      input: (exact ? "" : "*") + invoiceNumber + (exact ? "" : "*"),
    })
    return loads?.[0] ?? null
  }

  async searchLoadsByClientShippingId(
    clientShippingId: string
  ): Promise<Load[]> {
    const { loads } = await this.searchLoads({
      type: "client_shipping_id",
      input: clientShippingId,
    })
    return loads ?? []
  }

  async searchLoadsBySenderName(senderName: string): Promise<Load[]> {
    const { loads } = await this.searchLoads({
      type: "sender.name",
      input: `*${senderName}*`,
    })
    return loads ?? []
  }

  async searchLoadsByReceiverName(receiverName: string): Promise<Load[]> {
    const { loads } = await this.searchLoads({
      type: "receiver.name",
      input: `*${receiverName}*`,
    })
    return loads ?? []
  }

  async searchLoadsBySenderPhoneNumber(phoneNumber: string): Promise<Load[]> {
    const { loads } = await this.searchLoads({
      type: "sender.phone",
      input: phoneNumber,
    })
    return loads ?? []
  }

  async searchLoadsByReceiverPhoneNumber(phoneNumber: string): Promise<Load[]> {
    const { loads } = await this.searchLoads({
      type: "receiver.phone",
      input: phoneNumber,
    })
    return loads ?? []
  }

  async getDeliveryGroupByInvoiceNumber(
    invoiceNumber: string
  ): Promise<Load[]> {
    const { data } = await this.client.get(
      `/shipping/v1/loads/search?query=delivery_group_by_invoice_number=${invoiceNumber}`
    )
    return (data.loads ?? []) as Load[]
  }

  async getDeliveryGroupByLoadId(loadId: string): Promise<Load[]> {
    const { data } = await this.client.get(
      `/shipping/v1/loads/search?query=delivery_group_by_load_id=${loadId}`
    )
    return (data.loads ?? []) as Load[]
  }

  async scanLoad(
    stationId: string,
    loadId: string,
    cartId: string | null
  ): Promise<Load> {
    const { data } = await this.client.post(
      `/shipping/v1/stations/${stationId}/loads/${loadId}/scan`,
      {
        cartId,
      }
    )
    return data as Load
  }

  async listLoadPossessors(
    loadId: string
  ): Promise<ListLoadPossessorsResponse> {
    const { data } = await this.client.get(
      `/shipping/v1/loads/${loadId}/possessors`
    )
    return data as ListLoadPossessorsResponse
  }
  async getOrganization(organizationId: string): Promise<Organization> {
    const { data } = await this.client.get(
      `/shipping/v1/organizations/${organizationId}`
    )
    return data as Organization
  }
  async listOrganizations(
    queryParams: Record<string, string>
  ): Promise<Organization[]> {
    if (!queryParams.hasOwnProperty("page_size")) {
      queryParams["page_size"] = LIST_PAGE_SIZE
    }

    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/shipping/v1/organizations?${paramsString}`
    )
    return data.organizations as Organization[]
  }

  async retrieveOrganization(orgId: string): Promise<Organization> {
    const { data } = await this.client.get(
      `/shipping/v1/organizations/${orgId}`
    )
    return data as Organization
  }

  // track API

  async createDriver(params: {
    id: string
    fullName: string
    phone: string
    organizationId: string
    assignedStationId: string
    assignedRouteId: string
    startStationRouteSequence: number
    endStationRouteSequence: number
  }) {
    const { data } = await this.client.post("/track/v1/drivers/", params)
    return data as Driver
  }

  async patchDriver(
    id: string,
    {
      fullName,
      phone,
      organizationId,
      assignedStationId,
      assignedRouteId,
      startStationRouteSequence,
      endStationRouteSequence,
      active,
    }: Partial<
      Pick<
        Driver,
        | "fullName"
        | "phone"
        | "organizationId"
        | "assignedStationId"
        | "assignedRouteId"
        | "startStationRouteSequence"
        | "endStationRouteSequence"
        | "active"
      >
    >
  ) {
    const { data } = await this.client.patch(`/track/v1/drivers/${id}`, {
      ...(fullName === undefined ? {} : { fullName }),
      ...(phone === undefined ? {} : { phone }),
      ...(organizationId === undefined ? {} : { organizationId }),
      ...(assignedStationId === undefined ? {} : { assignedStationId }),
      ...(assignedRouteId === undefined ? {} : { assignedRouteId }),
      ...(startStationRouteSequence === undefined
        ? {}
        : { startStationRouteSequence }),
      ...(endStationRouteSequence === undefined
        ? {}
        : { endStationRouteSequence }),
      ...(active === undefined ? {} : { active }),
    })
    return data as Driver
  }

  async retrieveDriver(driverId: string): Promise<Driver> {
    const { data } = await this.client.get(`/track/v1/drivers/${driverId}`)
    return data as Driver
  }

  async getRestrictedOrgFilter(
    orgId: OrgId,
    queryParams: Record<string, string>
  ): Promise<Record<string, string>> {
    const notOrgFilters = queryParams.filter
      .split(";")
      .filter((f) => f && !f.startsWith("organization="))

    const filters = [...notOrgFilters]
    if (HANGANG_ORG_IDS.includes(orgId)) {
      filters.push(`organization_id=${HANGANG_ORG_IDS.join(",")}`)
    } else {
      filters.push(`organization_id=${orgId}`)
    }

    queryParams.filter = filters!.join(";")

    return queryParams
  }

  async listDrivers(
    queryParams: Record<string, string>
  ): Promise<ListDriversResponse> {
    const { orgId } = queryParams

    if (orgId) {
      queryParams = await this.getRestrictedOrgFilter(
        orgId as OrgId,
        queryParams
      )
    }

    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(`/track/v1/drivers?${paramsString}`)
    return data as ListDriversResponse
  }

  async listOrgDrivers(orgId: OrgId): Promise<Driver[]> {
    // 한강로지스의 경우에는 한강로지스, 한강로지스(픽업)의 Driver 를 모두 한강로지스의 Driver 로 본다.
    if (HANGANG_ORG_IDS.includes(orgId)) {
      return (
        await this.listDrivers({
          page_size: "100",
          filter: `organization_id=${HANGANG_ORG_IDS.join(",")}`,
        })
      ).drivers
    }

    return (
      await this.listDrivers({
        page_size: "100",
        filter: `organization_id=${orgId}`,
      })
    ).drivers
  }

  async listDriverRounds(
    driverId: string,
    queryParams: Record<string, string>
  ): Promise<ListDriverRoundsResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/track/v1/drivers/${driverId}/rounds?${paramsString}`
    )
    return data as ListDriverRoundsResponse
  }

  async retrieveTruck(
    truckId: string,
    type: "id" | "car_number" = "id"
  ): Promise<Truck> {
    const { data } = await this.client.get(
      `/track/v1/trucks/${truckId}?type=${type}`
    )
    return data as Truck
  }

  async patchTruck(
    truckId: string,
    {
      carNumber,
      organizationId,
    }: Partial<Pick<Truck, "carNumber" | "organizationId">>
  ) {
    const { data } = await this.client.patch(`/track/v1/trucks/${truckId}`, {
      ...(carNumber ? { carNumber } : {}),
      ...(organizationId ? { organizationId } : {}),
    })
    return data as Truck
  }

  async listTrucks(queryParams: Record<string, string>): Promise<Truck[]> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(`/track/v1/trucks?${paramsString}`)
    return data.trucks as Truck[]
  }

  async createTruck(
    organizationId: string,
    carNumber: string,
    carType: string,
    weightLimit: number
  ) {
    const { data } = await this.client.post("/track/v1/trucks/", {
      organizationId,
      carNumber,
      carType,
      weightLimit,
    })
    return data as Truck
  }

  // Rescue API

  async moveWrongLoad(
    targetStationId: string,
    targetCartId: string,
    loadId: string
  ): Promise<Load> {
    const { data } = await this.client.post(`/rescue/v1/loads/${loadId}/move`, {
      targetStationId,
      targetCartId,
    })
    return data as Load
  }

  async reportFoundCart(stationId: string, cartId: string) {
    return this.client.post(`/rescue/v1/carts/${cartId}/report-found`, {
      stationId,
    })
  }

  async forceDeliverLoad(
    loadId: string,
    imageUrl: string,
    handOverMethod: string,
    specialNote: string,
    extraPayload: any = {},
    location: {
      latitude: number
      longitude: number
    },
    sendNotification: boolean,
    organizationId?: string,
    driverId?: string,
    type?: "FIX_WRONG_DELIVERY" | "BEFORE_DELIVERY"
  ) {
    return this.client.post(`/rescue/v1/loads/${loadId}/force-deliver`, {
      imageUrl,
      handOverMethod,
      specialNote,
      extraPayload,
      location,
      sendNotification,
      ...(organizationId && { organizationId }),
      ...(driverId && { driverId }),
      ...(type && { type }),
    })
  }

  async quitLoad(
    loadId: string,
    quitStationId: string,
    reason: LoadQuitReason,
    specialNote?: string
  ) {
    return this.client.post(`/rescue/v1/loads/${loadId}/quit`, {
      quitStationId,
      reason,
      specialNote,
    })
  }

  async reportFoundLoad(stationId: string, loadId: string) {
    return this.client.post(`/rescue/v1/loads/${loadId}/report-found`, {
      stationId,
    })
  }

  async listBuildingNotes(
    loadId: string,
    isReturning: boolean
  ): Promise<{
    notes: BuildingNote[]
    buildingId: string
  }> {
    const { data } = await this.client.get(
      `/location/v1/loads/${loadId}/${
        isReturning ? "sender" : "receiver"
      }/building/notes`
    )
    return data
  }

  async addBuildingNote(
    loadId: string,
    isReturning: boolean,
    accessMethod: string
  ): Promise<BuildingNote> {
    const { data } = await this.client.post(
      `/location/v1/loads/${loadId}/${
        isReturning ? "sender" : "receiver"
      }/building/notes`,
      {
        accessMethod,
      }
    )
    return data
  }

  async deleteBuildingNote(buildingId: string, noteId: string): Promise<void> {
    await this.client.delete(
      `/location/v1/buildings/${buildingId}/notes/${noteId}`
    )
  }

  async listRegionSets(
    filter?: {
      regionId?: string
      policyType?: string
    },
    pageToken = "",
    pageSize = LIST_PAGE_SIZE,
    includePolygon = false
  ): Promise<PaginationResponse<"regionSets", RegionSet>> {
    const params = new URLSearchParams({
      page_token: pageToken,
      page_size: pageSize,
    })
    if (filter) {
      const filters: string[] = []
      if (filter.regionId) {
        filters.push(`region_id=${filter.regionId}`)
      }
      if (filter.policyType) {
        filters.push(`policy_type=${filter.policyType}`)
      } else {
        filters.push(
          `policy_type=${PolicyTypes.filter(
            (p) => p !== "TODAY_OVERNIGHT"
          ).join(",")}`
        )
      }
      if (filters.length) {
        params.append("filter", filters.join(";"))
      }
    }
    if (includePolygon) {
      params.append("include_polygon", "true")
    }
    const { data } = await this.client.get(`/location/v1/region-sets?${params}`)
    return data
  }

  async logEvents(events: any[]) {
    return this.client.post(`/util/v1/events/bulk-create`, { events })
  }

  // Stats API

  async getRealTimeStatistics(
    queryParams: Record<string, string>
  ): Promise<IStats> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(`/stats/v1/stats?${paramsString}`)
    return data as IStats
  }

  async listDriversRealTimeStatistics(
    queryParams: Record<string, string>
  ): Promise<
    ListResourceRealTimeStatisticsResponse<
      "driverId",
      { latestDeliverTime?: string }
    >
  > {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/stats/v1/drivers/stats?${paramsString}`
    )
    return data as ListResourceRealTimeStatisticsResponse<"driverId">
  }

  async listRegionSetsRealTimeStatistics(
    policyType: string,
    queryParams: Record<string, string>
  ): Promise<ListResourceRealTimeStatisticsResponse<"regionSetName">> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/stats/v1/policies/${policyType}/region-sets/stats?${paramsString}`
    )
    return data as ListResourceRealTimeStatisticsResponse<"regionSetName">
  }

  async listOrganizationsRealTimeStatistics(
    queryParams: Record<string, string>
  ): Promise<ListResourceRealTimeStatisticsResponse<"organizationId">> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/stats/v1/organizations/stats?${paramsString}`
    )
    return data as ListResourceRealTimeStatisticsResponse<"organizationId">
  }

  async listClientsRealTimeStatistics(
    queryParams: Record<string, string>
  ): Promise<ListResourceRealTimeStatisticsResponse<"clientId">> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/stats/v1/clients/stats?${paramsString}`
    )
    return data as ListResourceRealTimeStatisticsResponse<"clientId">
  }

  async listAddressesRealTimeStatistics(
    queryParams: Record<string, string>
  ): Promise<ListResourceRealTimeStatisticsResponse<"siDo" | "siGunGu">> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/stats/v1/addresses/stats?${paramsString}`
    )
    return data as ListResourceRealTimeStatisticsResponse<"siDo" | "siGunGu">
  }

  async listEventCounts(
    queryParams: Record<string, string>
  ): Promise<ListEventCountsResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/stats/v1/events/counts?${paramsString}`
    )
    return data as ListEventCountsResponse
  }

  async listDriverEventCounts(
    driverId: string,
    queryParams: Record<string, string>
  ): Promise<ListEventCountsResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/stats/v1/drivers/${driverId}/events/counts?${paramsString}`
    )
    return data as ListEventCountsResponse
  }

  async listRegionSetEventCounts(
    policyType: string,
    regionSetName: string,
    queryParams: Record<string, string>
  ): Promise<ListEventCountsResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/stats/v1/policies/${policyType}/region-sets/${encodeURIComponent(
        regionSetName
      )}/events/counts?${paramsString}`
    )
    return data as ListEventCountsResponse
  }

  async listOrganizationEventCounts(
    organizationId: string,
    queryParams: Record<string, string>
  ): Promise<ListEventCountsResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/stats/v1/organizations/${organizationId}/events/counts?${paramsString}`
    )
    return data as ListEventCountsResponse
  }

  async listClientEventCounts(
    clientId: string,
    queryParams: Record<string, string>
  ): Promise<ListEventCountsResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/stats/v1/clients/${clientId}/events/counts?${paramsString}`
    )
    return data as ListEventCountsResponse
  }

  async listAddressEventCounts(
    siDo: string,
    siGunGu: string,
    queryParams: Record<string, string>
  ): Promise<ListEventCountsResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/stats/v1/addresses/${encodeURIComponent(siDo)}/${encodeURIComponent(
        siGunGu
      )}/events/counts?${paramsString}`
    )
    return data as ListEventCountsResponse
  }

  async getClientsSummary(
    queryParams: Record<string, string>
  ): Promise<ClientsSummary> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/stats/v1/clients/summary?${paramsString}`
    )
    return data as ClientsSummary
  }

  async listIssues(
    queryParams: Record<string, string>
  ): Promise<ListIssuesResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/shipping/v1/issues?${paramsString}`
    )
    return data as ListIssuesResponse
  }

  async retrieveIssue(issueId: string): Promise<Issue> {
    const { data } = await this.client.get(`/shipping/v1/issues/${issueId}`)
    return data as Issue
  }

  async createIssue(
    loadId: string,
    type: string,
    stationId?: string,
    comments: Comment[] = [],
    extraPayload: any = {}
  ): Promise<Issue> {
    const { data } = await this.client.post(
      `/shipping/v1/loads/${loadId}/issues`,
      {
        type,
        comments,
        extraPayload,
        ...(stationId ? { stationId } : {}),
      }
    )

    return data as Issue
  }

  async updateIssue(
    issueId: string,
    state: string,
    author: string
  ): Promise<Issue> {
    const { data } = await this.client.patch(`/shipping/v1/issues/${issueId}`, {
      state,
      author,
    })
    return data as Issue
  }

  async listLoadIssues(loadId: string): Promise<ListIssuesResponse> {
    const { data } = await this.client.get(
      `/shipping/v1/loads/${loadId}/issues`
    )
    return data as ListIssuesResponse
  }

  async createIssueComment(
    issueId: string,
    author: string,
    source: string,
    state: string,
    content: string,
    extraPayload: any | null
  ): Promise<IssueComment> {
    const { data } = await this.client.post(
      `/shipping/v1/issues/${issueId}/comments`,
      {
        author,
        source,
        state,
        content,
        extraPayload,
      }
    )
    return data as IssueComment
  }

  async updateIssueComment(
    issueId: string,
    issueComment: IssueComment
  ): Promise<IssueComment> {
    const { data } = await this.client.put(
      `/shipping/v1/issues/${issueId}/comments/${issueComment.id}`,
      issueComment
    )
    return data as IssueComment
  }

  async listAlimtalkConditions(
    queryParams: Record<string, string>
  ): Promise<AlimtalkCondition[]> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/track/v1/alimtalk-conditions?${paramsString}`
    )
    return data.alimtalkConditions.map((condition: AlimtalkCondition) => {
      // XXX: extraTemplateParameters의 key는 snake_case를 유지한다.
      condition.extraTemplateParameters = convertKeysToSnakeCase(
        condition.extraTemplateParameters
      )
      return condition
    }) as AlimtalkCondition[]
  }

  async getAlimtalkCondition(conditionId: string): Promise<AlimtalkCondition> {
    const { data } = await this.client.get(
      `/track/v1/alimtalk-conditions/${conditionId}`
    )
    // XXX: extraTemplateParameters의 key는 snake_case를 유지한다.
    data.extraTemplateParameters = convertKeysToSnakeCase(
      data.extraTemplateParameters
    )
    return data as AlimtalkCondition
  }

  async createAlimtalkCondition(
    condition: AlimtalkCondition
  ): Promise<AlimtalkCondition> {
    const { data } = await this.client.post(
      `/track/v1/alimtalk-conditions`,
      condition
    )
    // XXX: extraTemplateParameters의 key는 snake_case를 유지한다.
    data.extraTemplateParameters = convertKeysToSnakeCase(
      data.extraTemplateParameters
    )
    return data as AlimtalkCondition
  }

  async updateAlimtalkCondition(
    conditionId: string,
    condition: AlimtalkCondition
  ): Promise<AlimtalkCondition> {
    const { data } = await this.client.put(
      `/track/v1/alimtalk-conditions/${conditionId}`,
      condition
    )
    // XXX: extraTemplateParameters의 key는 snake_case를 유지한다.
    data.extraTemplateParameters = convertKeysToSnakeCase(
      data.extraTemplateParameters
    )
    return data as AlimtalkCondition
  }

  async deleteAlimtalkCondition(conditionId: string): Promise<void> {
    await this.client.delete(`/track/v1/alimtalk-conditions/${conditionId}`)
  }

  async sendAlimtalk(
    sendOpts: SendManualAlimtalkRequest
  ): Promise<SendManualAlimtalkResponse> {
    const { data } = await this.client.post(`/track/v1/send-alimtalk`, sendOpts)
    return data as SendManualAlimtalkResponse
  }

  async listFailedAlimtalks(
    queryParams: Record<string, string>
  ): Promise<ListFailedAlimtalksResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/track/v1/failed-alimtalks?${paramsString}`
    )
    return data
  }

  async retryFailedAlimtalks(
    retryReq: RetryFailedAlimtalkRequest
  ): Promise<RetryFailedAlimtalkReponse> {
    const { data } = await this.client.post(
      `/track/v1/failed-alimtalks/retry`,
      retryReq
    )
    return data as RetryFailedAlimtalkReponse
  }
  async listHoldOrQuitType(): Promise<HoldOrQuitType[]> {
    const { data } = await this.client.get(`/util/v1/hold-or-quit-types`)
    return data.results
  }

  async retrieveHoldOrQuitType(id: string): Promise<HoldOrQuitType> {
    const { data } = await this.client.get(`/util/v1/hold-or-quit-types/${id}`)
    return data
  }

  async uploadFile(
    file: File,
    category: FileCategory = "shipping-photo"
  ): Promise<FileResponse> {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("category", category)

    const { data } = await this.client.post(`/util/v1/files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    return data
  }

  async listStationRouteFlows(stationId: string, policyTypes: string) {
    const { data } = await this.client.get(
      `/shipping/v1/stations/${stationId}/route-flows?policy_types=${policyTypes}`
    )
    return data.routeFlows as RouteFlow[]
  }

  async listLoadsInStation(
    stationId: string,
    queryParams: Record<string, string>
  ): Promise<ListLoadsResponse> {
    const paramsString = new URLSearchParams(queryParams)
    const { data } = await this.client.get(
      `/shipping/v1/stations/${stationId}/loads?${paramsString}`
    )
    return data
  }
}

export type ListResourceRealTimeStatisticsResponse<
  K extends string,
  Extras = {}
> = {
  stats: (IResourceStats<K> & Extras)[]
  totalCount: number
  nextPageToken: string
}

export type ListEventCountsResponse = {
  counts: IEventWindow[]
  totalCount: number
  nextPageToken: string
}

function convertKeysToSnakeCase(obj: { [key: string]: string }): {
  [key: string]: string
} {
  const newObj: { [key: string]: string } = {}
  Object.keys(obj).forEach((key) => {
    const newKey = key.replace(/[A-Z]/g, (v) => `_${v.toLowerCase()}`)
    newObj[newKey] = obj[key]
  })
  return newObj
}

export const mockLoad = {
  id: "5aa7c223-4c93-bfc0-68d9-26f5aaebe3bc",
  invoiceNumber: "123456789012",
  clientId: "123456789",
  clientName: "브이투브이",
  shippingPlaceId: "04c1fde5-1c42-b46e-97e1-7826c223fa65",
  clientOrderId: "987654321",
  clientShippingId: "11112222333343444",
  state: "DELIVERED",
  substate: null,
  returnReserved: true,
  deliveryClass: "TO_24",
  shippingType: "STATION_TO_LM",
  endUserType: "B2C",
  policyType: "TODAY_EVENING",
  orderSource: "END_USER",
  orderTime: "2021-04-26T12:34:00+09:00",
  takeOutTime: "2021-04-26T12:56:00+09:00",
  readyTime: "2021-04-26T12:56:00+09:00",
  standByTime: "2021-04-26T14:56:00+09:00",
  lostTime: "2021-04-26T15:56:00+09:00",
  targetDeliverTime: "2021-04-27T00:00:00+09:00",
  outsourcingInfo: null,
  cartId: null,
  truckId: null,
  plannedTruckId: null,
  stationId: null,
  scannedStationId: null,
  shippingPath: [
    {
      routeId: "eda35207-2fc6-455c-a371-dcdca310bb14",
      sourceStation: {
        id: "04c1fde5-1c42-b46e-97e1-7826c223fa65",
        sequence: 0,
      },
      targetStation: {
        id: "e9f79230-e5da-4f43-a88e-58e312759899",
        sequence: 1,
      },
      finished: true,
    },
  ],
  sourceStationId: null,
  targetStationId: null,
  name: "Apple MacBook Pro",
  dimension: {
    width: 320,
    depth: 200,
    height: 140,
  },
  fragile: true,
  weight: 2300,
  sender: {
    shippingPlaceId: "04c1fde5-1c42-b46e-97e1-7826c223fa65",
    name: "홍길동",
    phone: "010-0000-0000",
    rawAddress:
      "서울특별시 중구 삼일대로 343, 대신 파이낸스 센터, 위워크 9층 118호",
    address: {
      postalCode: "04538",
      siDo: "서울",
      siGunGu: "역삼동",
      eupMyeonDong: "역삼동",
      administrativeDistrict: "역삼제1동",
      regionBaseAddress: "서울 중구 저동1가 114동",
      regionDetailAddress: "대신 파이낸스 센터 위워크 9층 118호",
      streetBaseAddress: "서울 중구 삼일대로 343",
      streetDetailAddress: "대신 파이낸스 센터 위워크 9층 118호 (저동1가)",
      buildingName: "대신파이낸스센터(Daishin Finance Center)",
      buildingNumber: "1동",
      buildingId: "1114013100100480000020695",
    },
    location: {
      latitude: 37.5519,
      longitude: 126.9917,
    },
    lastMileInfo: {
      regionId: "",
      regionSetPolicyType: "TODAY_EVENING",
      regionSetName: "",
    },
    accessMethod: "현관 비밀번호 1234",
    preference: "문앞에 놔주세요",
  },
  receiver: {
    shippingPlaceId: "04c1fde5-1c42-b46e-97e1-7826c223fa65",
    name: "홍길동",
    phone: "010-0000-0000",
    rawAddress:
      "서울특별시 중구 삼일대로 343, 대신 파이낸스 센터, 위워크 9층 118호",
    address: {
      postalCode: "04538",
      siDo: "서울",
      siGunGu: "역삼동",
      eupMyeonDong: "역삼동",
      administrativeDistrict: "역삼제1동",
      regionBaseAddress: "서울 중구 저동1가 114동",
      regionDetailAddress: "대신 파이낸스 센터 위워크 9층 118호",
      streetBaseAddress: "서울 중구 삼일대로 343",
      streetDetailAddress: "대신 파이낸스 센터 위워크 9층 118호 (저동1가)",
      buildingName: "대신파이낸스센터(Daishin Finance Center)",
      buildingNumber: "1동",
      buildingId: "1114013100100480000020695",
    },
    location: {
      latitude: 37.5519,
      longitude: 126.9917,
    },
    lastMileInfo: {
      regionId: "e9f79230-e5da-4f43-a88e-58e312759899",
      regionSetPolicyType: "TODAY_EVENING",
      regionSetName: "역삼",
    },
    accessMethod: "현관 비밀번호 1234",
    preference: "문앞에 놔주세요",
  },
  pickUpInfo: {
    pickUpTime: "2021-04-26T12:56:00+09:00",
    imageUrl: "https://cdn.imweb.me/thumbnail/20230915/55011c85370e0.jpg",
    handOverMethod: "문앞 회수",
    specialNote: "특이사하 없음",
    location: {
      latitude: 37.5519,
      longitude: 126.9917,
    },
    extraPayload: {},
  },
  deliveryInfo: {
    deliverTime: "2021-04-26T18:56:00+09:00",
    imageUrl: "https://cdn.imweb.me/thumbnail/20230915/55011c85370e0.jpg",
    handOverMethod: "문앞 전달",
    extraPayload: {
      memo: "",
      parcel_locker_info: "1번  비번 1234",
    },
    specialNote: "특이사항 없음",
  },
  holdDeliveryInfo: {
    holdTime: "2021-04-26T17:56:00+09:00",
    imageUrl: "https://cdn.imweb.me/thumbnail/20230915/55011c85370e0.jpg",
    type: "WRONG_PASSWORD",
    reason: "공동현관 비밀번호 부재",
    specialNote: "비밀번호 틀림",
    location: {
      latitude: 37.5519,
      longitude: 126.9917,
    },
  },
  holdPickUpInfo: {
    holdTime: "2021-04-26T17:59:00+09:00",
    imageUrl: "https://cdn.imweb.me/thumbnail/20230915/55011c85370e0.jpg",
    type: "WRONG_PASSWORD",
    reason: "공동현관 비밀번호 부재",
    specialNote: "비밀번호 틀림",
    location: {
      latitude: 37.5519,
      longitude: 126.9917,
    },
  },
  unholdDeliveryInfo: {
    unholdTime: "2021-04-26T18:10:00+09:00",
    unholdCount: 2,
    extraPayload: {},
  },
  unholdPickUpInfo: {
    unholdTime: "2021-04-26T18:10:01+09:00",
    unholdCount: 3,
    extraPayload: {},
  },
  quitInfo: {
    quitTime: "2021-04-26T20:10:01+09:00",
    reason: "CLIENT_REQUEST",
    specialNote: "특이사항 없음",
    extraPayload: {},
  },
  lastPossessor: {
    organizationId: "70000da1-900d-45d2-93cc-8dc9c8b35dc6",
    driverId: "651621e8-c24a-d3c0-b3b4-7879be0e7381",
    driverName: "홍길동",
    driverPhone: "010-1234-5678",
  },
  cautionTypes: ["TRANSLATED_ADDRESS"],
  developerPayload: '{"key":"value"}',
  abTestShard: 0,
  abTests: {},
} as Load
